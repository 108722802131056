
import { useEffect, useRef, useState } from 'react';
import './myspecialcss.css'

const circleFillIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16">
<circle cx="8" cy="8" r="8"/>
</svg>;

const circleIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle" viewBox="0 0 16 16">
<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
</svg>;

const playFillIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-play-fill" viewBox="0 0 16 16">
<path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393"/>
</svg>;

const squareFillIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-square-fill" viewBox="0 0 16 16">
<path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2z"/>
</svg>;

const squareIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="bi bi-square" viewBox="0 0 16 16">
<path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
</svg>;

const triangleIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-play" viewBox="0 3 15 11">
<path d="M10.804 8 5 4.633v6.734zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696z" />
</svg>;


const RecordingActivity = () => {
    const [circleButtonClicked, setCircleButtonClicked] = useState(false);
    const [isFinalAnswer, setIsFinalAnswer] = useState(null);
    const [showReadMe, setShowReadMe] = useState(false);
    const [stopButtonClicked, setStopButtonClicked] = useState(false);
    const [triangleButtonClicked, setTriangleButtonClicked] = useState(false);
    const userInputTextareaRef = useRef(null);

    useEffect(() => {
        resetStopButtonAfterClick();
    }, [stopButtonClicked]);

    const resetStopButtonAfterClick = () => {
        if(stopButtonClicked) {
            setTimeout(() => {
                setStopButtonClicked(false);
            }, 500);
        }
    }

    return <div>
        <div className='mb-3'>
            {showReadMe ?             <p className='cursor' onClick={() => setShowReadMe(false)}>
                <span className='text-pink'>// </span>
                <span className='text-secondary'>close README.md</span>
            </p> :             <p className='cursor' onClick={() => setShowReadMe(true)}>
                <span className='text-pink'>// </span>
                <span className='text-secondary'>README.md</span>
            </p>}
        </div>
        {showReadMe ? <div style={{ color: 'lightgray', maxWidth: '500px'}}>
            Thanks for checking out the ReadMe for the Vista Higher Learning coding challenge! This is an app built using ReactJS.<br/><br/>
            The entry point of the app is /vite-project/src/App.tsx, in which you will see reference to a RecordingActivity component. This file holds html that renders the form, as well as
            the icons references and useState hooks to control logic.

            I was going to use a utility class from Bootstrap, but this ended up being so quick that I just mocked in some util classes that fit this project. Additionally, would like to use JSDoc
            or Typescript to help enforce some typing.<br /><br/>
            I had a blast doing this challenge, thanks for the opportunity!
            <br/> <br />
            Roland Forbes
        </div> : <>
        <form className='recording-activity-form'>
            <p className='mb-2 text-end'>
                Say the vocabulary words.
            </p>
            <fieldset>
                <div className='align-items-center cursor d-flex gap-2 justify-content-end mb-3 xs-breakpoint-vertical'>
                    <div className='card d-flex d-flex-vertical gap-3'>
                        <div className='d-flex justify-content-center'>
                            <button className={['round-btn square-icon', stopButtonClicked ? 'square-icon-clicked' : ''].join(' ')}
                                disabled={stopButtonClicked}
                                onClick={() => {
                                    if(triangleButtonClicked) userInputTextareaRef?.current?.focus();
                                    setStopButtonClicked(true);
                                    setCircleButtonClicked(false);
                                    setTriangleButtonClicked(false);
                                }}
                                type='button'>
                                {stopButtonClicked ? squareFillIcon : squareIcon}
                            </button>
                        </div>
                        <small className='text-xs uppercase'>STOP</small>
                    </div>
                    <div className='card d-flex d-flex-vertical gap-3'>
                        <div className='d-flex justify-content-center'>
                            <button className={['round-btn circle-icon', circleButtonClicked ? 'circle-icon-clicked' : ''].join(' ')} 
                                onClick={() => setCircleButtonClicked(!circleButtonClicked)}
                                type='button'>
                                {circleButtonClicked ? circleFillIcon : circleIcon}
                            </button>
                        </div>
                        <small className='text-xs uppercase'>RECORD</small>
                    </div>
                    <div className='card d-flex d-flex-vertical gap-3'>
                        <div className='d-flex justify-content-center'>
                            <button className={['round-btn triangle-icon', triangleButtonClicked ? 'triangle-icon-clicked' : ''].join(' ')} 
                                onClick={() => setTriangleButtonClicked(!triangleButtonClicked)}
                                type='button'>
                                {triangleButtonClicked ? playFillIcon : triangleIcon}
                            </button>
                        </div>
                        <small className='text-xs uppercase'>REVIEW <br /> YOUR <br /> RECORDING</small>
                    </div>
                </div>
                <div className='d-flex mb-3'>
                    <textarea className='w-100'
                        ref={userInputTextareaRef}
                        rows='6'
                        type='text' />
                </div>
            </fieldset>
            <fieldset className='align-items-center d-flex gap-3 justify-content-between xs-breakpoint-vertical'>
                <span className='align-items-center d-flex gap-3 xs-breakpoint-vertical'>
                    <label>Is this your final answer?</label>
                    <span className='card d-flex gap-1'>
                        <small className='opacity-75'>Yes</small>
                        <input checked={isFinalAnswer == true} 
                            label='Yes' 
                            onClick={() => isFinalAnswer == true ? setIsFinalAnswer(null) : setIsFinalAnswer(!isFinalAnswer)} 
                            type='checkbox' readOnly />
                    </span>
                    <span className='card d-flex gap-1'>
                        <small className='opacity-75'>No</small>
                        <input checked={isFinalAnswer == false} 
                            label='Yes' 
                            onClick={() => isFinalAnswer == false ? setIsFinalAnswer(null) : setIsFinalAnswer(false)} 
                            type='checkbox' 
                            readOnly />
                    </span>
                </span>
                <button className={`submit-btn-${isFinalAnswer == true ? 'enabled' : 'disabled'}`}
                    disabled={isFinalAnswer !== true}
                    type='submit'>
                    SUBMIT
                </button>
            </fieldset>
        </form>
        </>}

    </div>
}
export default RecordingActivity;